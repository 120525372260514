/**
 * @param {*[]} allPosts
 * @param {*[]} posts
 * @returns {*[]} dedupedPosts
 */
function dedupePosts(allPosts, posts) {
  const uniqueKeys = allPosts.map(p => p.key)
  return posts.filter(post => !uniqueKeys.includes(post.key))
}

/**
 *
 * @param {boolean} refresh
 * @param {*[]} allPosts
 * @param {*[]} newPosts
 * @returns {*[]}
 */
function dedupeAndSort(refresh, allPosts, newPosts) {
  const dedupedPosts = dedupePosts(allPosts, newPosts)

  if (refresh) {
    return dedupedPosts.concat(allPosts).map((p, index) => {
      p.setIndex(index)
      return p
    })
  }
  return allPosts.concat(dedupedPosts).map((p, index) => {
    p.setIndex(index)
    return p
  })
}

export default dedupeAndSort
