const DEFAULT_EMBED_LANGUAGE = 'en'
const VALID_LANGUAGE_REGEX = /^[a-z]{2}$/

function getLanguage(locale) {
  if (locale && locale.match(VALID_LANGUAGE_REGEX)) return locale

  if (locale && locale.indexOf('-') !== -1) {
    return locale && locale.split('-')[0]
  }

  return DEFAULT_EMBED_LANGUAGE
}

export default getLanguage
