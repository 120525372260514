import React, { Component } from 'react'
import { string, bool, shape, instanceOf } from 'prop-types'

import debounce from '../utilities/debounce'
import { setupAndStylingStoreHasKey, setupAndStylingStore } from '../../flowsStore'
import Settings from '../../models/settings'
import Locale from '../../models/Locale'
import Feed from '../components/feed/Feed'
import fetchFeed from './fetch-feed'
import sendEmbedLoadedEvent from './send-embed-loaded-event'
import dedupeAndSort from './dedupe-posts-and-sort'
import fetchTagFeed from './fetch-tag-feed'
import { VisiblePostsContext } from '../contexts/visiblePostsContext'

const DEFAULT_STATE = {
  allPosts: [],
  cursor: null,
  settings: {},
  shouldFetchMore: true,
}

class FeedLoader extends Component {
  static propTypes = {
    allowCookies: bool,
    containerElement: shape().isRequired,
    context: string,
    feedKey: string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    flowConfiguration: shape().isRequired,
    locale: instanceOf(Locale).isRequired,
    operator: string,
    productId: string,
    tags: string,
  }

  static defaultProps = {
    allowCookies: true,
    context: undefined,
    operator: undefined,
    productId: undefined,
    tags: undefined,
  }

  constructor(props) {
    super(props)
    this.state = { ...DEFAULT_STATE }
    this.isEmbedLoaded = false
    this.prevContext = {}
    /**
     * Wait for a little bit before sending the event when this function is called,
     * as the data to be sent might still be in the process of updating.
     */
    this.sendEmbedLoadedEvent = debounce(sendEmbedLoadedEvent, 500)
  }

  async componentDidMount() {
    await this.loadPosts()
    this.prevContext = this.context
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.feedKey !== this.props.feedKey ||
      prevProps.productId !== this.props.productId ||
      prevProps.tags !== this.props.tags ||
      prevProps.operator !== this.props.operator ||
      (this.isInSetupAndStyling() && setupAndStylingStoreHasKey(this.props.feedKey))
    ) {
      const newSettings = setupAndStylingStore(this.props.feedKey)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...DEFAULT_STATE }, () => this.loadPosts(true, newSettings))
    }
    if (
      this.prevContext?.visiblePostIds !== this.context.visiblePostIds &&
      this.context.visiblePostIds instanceof Array
    ) {
      this.sendEmbedLoadedEvent(this)
    }
    this.prevContext = this.context
  }

  loadPosts = async (refresh, setupAndStylingSettings = undefined) => {
    const { shouldFetchMore: currentShouldFetchMore, allPosts: currentAllPosts } = this.state

    const currentSettings = new Settings(this.state.settings)
    const isTagFlow = !!this.props.tags

    if (currentShouldFetchMore) {
      if (currentAllPosts.length >= currentSettings.postCount) {
        this.setState(prevState => ({
          ...prevState,
          shouldFetchMore: false,
        }))
      } else {
        const { feedKey, productId, tags, operator, allowCookies, locale } = this.props
        const config = {
          feedKey,
          productId,
          tags,
          operator,
          cursor: this.state.cursor,
          allowCookies,
          locale,
        }

        const typeOfFlowToFetch = isTagFlow ? fetchTagFeed : fetchFeed

        const response = await typeOfFlowToFetch(config, refresh)

        if (response !== undefined) {
          const [settings, posts, cursor, shouldFetchMore] = response
          this.setState(prevState => ({
            ...prevState,
            allPosts: dedupeAndSort(refresh, prevState.allPosts, posts),
            cursor,
            settings: setupAndStylingSettings || settings,
            shouldFetchMore,
          }))
        }
      }
    }
  }

  isInSetupAndStyling() {
    return this.props.context === 'setup-and-styling'
  }

  render() {
    const { locale, context, feedKey, allowCookies, containerElement } = this.props
    const { allPosts, settings, shouldFetchMore } = this.state

    const newSettings = this.isInSetupAndStyling() ? settings : undefined

    const feedProps = {
      containerElement,
      context,
      feed: {
        posts: allPosts,
      },
      feedKey,
      load: ({ refresh = false } = {}) => {
        return this.loadPosts(refresh, newSettings)
      },
      locale,
      shouldFetchMore,
      settings: new Settings({
        ...settings,
        allowCookies,
        locale,
      }),
    }

    return allPosts.length ? <Feed context={this.props.context} feedProps={feedProps} /> : null
  }
}
FeedLoader.contextType = VisiblePostsContext
export default FeedLoader
