const PLACEHOLDER = `
.bzfy-feed {
    /* Put your custom feed container styles here */
    // color: red !important;
}

.bzfy-feed-banner {
    /* Put your custom banner styles here */
    // color: red !important;
}
`.replace(/\s/g, '')

function matchesPlaceholder(customCssObject) {
  if (customCssObject?.value) {
    return PLACEHOLDER === customCssObject.value.replace(/\s/g, '')
  }
  return undefined
}

class CustomCss {
  constructor(customCssObject) {
    this.customCss = !matchesPlaceholder(customCssObject) && customCssObject?.value
  }

  toString() {
    return this.customCss
  }
}

export default CustomCss
