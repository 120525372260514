// locale is used for catalogs
// language is used for translations
import getLanguage from '../common/utilities/getLanguage'
import loadLanguage from '../common/utilities/loadLanguage'
import convertToCorrectLocale from '../common/utilities/convertToCorrectLocale'

const VALID_LOCALE_REGEX = /^[a-z]{2}-[a-zA-Z]{2}$/
const VALID_LANGUAGE_REGEX = /^[a-z]{2}$/

class Locale {
  constructor(locale) {
    if (locale && locale.match(VALID_LOCALE_REGEX)) {
      this.locale = locale.toLowerCase()
    } else if (locale && locale.match(VALID_LANGUAGE_REGEX)) {
      this.locale = convertToCorrectLocale(locale)
    } else if (locale === 'auto') {
      this.locale = window.navigator.userLanguage || window.navigator.language
    } else {
      this.locale = undefined
    }
    this.language = getLanguage(this.locale)
    this.translations = {
      postAltText: () => {}, // this is a noop needed for Setup and Styling due to promise not resolved from loadLanguage
    }

    this.loadLanguage()
  }

  loadLanguage() {
    return loadLanguage(this.language).then(module => {
      this.translations = module.default
    })
  }

  get fullLocale() {
    return this.locale && this.locale.match(VALID_LOCALE_REGEX) ? this.locale : ''
  }

  getTranslation(key) {
    return this.translations[key]
  }

  setLocaleBasedOnProductlist(locale) {
    if (locale && this.locale === undefined) {
      this.language = getLanguage(locale)
      this.locale = locale
      this.loadLanguage()
    }
  }

  get isRTL() {
    return ['ar'].includes(this.language)
  }

  toString() {
    return `New Locale: ${this.locale}. Language: ${this.language} `
  }
}

export default Locale
