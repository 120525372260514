export default function addEmbedEventListeners(cb) {
  function callbackWithEventHandler() {
    return cb({ removeEventListeners })
  }

  function removeEventListeners() {
    window.removeEventListener('scroll', callbackWithEventHandler, false)
    window.removeEventListener('resize', callbackWithEventHandler, false)
  }

  window.addEventListener('scroll', callbackWithEventHandler, false)
  window.addEventListener('resize', callbackWithEventHandler, false)
}
