import { validateColor, validateValue, validateText } from '../common/utilities/validateValues'

class Banner {
  constructor(elements) {
    this.banner = {
      children: {},
      ...((elements && elements.banner) || {}),
    }
  }

  get backgroundColor() {
    const { backgroundColor } = this.banner.children
    const value = backgroundColor && backgroundColor.value
    return validateColor(value, '#ffffff')
  }

  get padding() {
    const { paddingSize } = this.banner.children
    const value = paddingSize && paddingSize.value
    return validateValue(value, 20)
  }

  get logoUrl() {
    const { logoUrl } = this.banner.children
    const value = logoUrl && logoUrl.value
    return validateText(value, undefined)
  }

  get hideLogoMobile() {
    const { hideLogoMobile } = this.banner.children
    const value = hideLogoMobile && hideLogoMobile.value
    return validateValue(value, false)
  }

  get primaryText() {
    const { tagline } = this.banner.children
    const value = tagline && tagline.value
    return validateText(value, '')
  }

  get primaryFontSize() {
    const { taglineFontSize } = this.banner.children
    const value = taglineFontSize && taglineFontSize.value
    return validateValue(value, 18)
  }

  get primaryColor() {
    const { taglineColor } = this.banner.children
    const value = taglineColor && taglineColor.value
    return validateColor(value, '#000000')
  }

  get secondaryText() {
    const { title } = this.banner.children
    const value = title && title.value
    return validateText(value, '')
  }

  get secondaryFontSize() {
    const { titleFontSize } = this.banner.children
    const value = titleFontSize && titleFontSize.value
    return validateValue(value, 26)
  }

  get secondaryColor() {
    const { titleColor } = this.banner.children
    const value = titleColor && titleColor.value
    return validateColor(value, '#000000')
  }

  get shouldShow() {
    const value = this.banner && this.banner.value
    return validateValue(value, false)
  }

  get position() {
    const { position } = this.banner.children
    const value = position && position.value
    return validateValue(value, 'top')
  }

  get showBottom() {
    return this.shouldShow && this.position === 'bottom'
  }

  get showCard() {
    return this.shouldShow && this.position === 'card'
  }

  get showTop() {
    return this.shouldShow && this.position === 'top'
  }
}

export default Banner
