import { PLATFORMS } from '../common/utilities/constants'
import PostMedia from './PostMedia'

class Post {
  constructor(rawPost) {
    /* Since we're working schemaless these keys can differ from posts.
     * Looping makes more sense here.
     * This keep will keep the same interface as well as the post object
     */
    Object.keys(rawPost).forEach(key => {
      if (key === 'productKeys') {
        this.rawProductKeys = rawPost[key]
      } else if (key === 'media') {
        this.rawMedia = (rawPost[key] || []).map(m => new PostMedia(m))
      } else if (key === 'text') {
        this.rawText = rawPost[key]
      } else {
        this[key] = rawPost[key]
      }
    })
  }

  get text() {
    return this.rawText
  }

  get characterCount() {
    const text = this.text || ''
    return text.length
  }

  get productKeys() {
    if (typeof this.rawProductKeys === 'string') {
      this.rawProductKeys = JSON.parse(this.rawProductKeys)
    }
    return this.rawProductKeys
  }

  get media() {
    return this.rawMedia || []
  }

  getMediaFromIndex(index) {
    const media = this.media[index]
    return media || new PostMedia({})
  }

  get hasProducts() {
    return this.rawProductKeys && !!this.rawProductKeys.length
  }

  get hasMedia() {
    return this.media && this.media.length > 0
  }

  get isCarouselPost() {
    return this.media.length > 1
  }

  get hasVideo() {
    return this.hasMedia && this.media[0].isVideo
  }

  get isYoutubePost() {
    return this.sourceName === PLATFORMS.YOUTUBE
  }

  getIsVideoPost(index = 0) {
    return this.hasMedia && this.media[index].isVideo
  }

  getRenderImage(mediaIndex = 0) {
    let { thumbnail } = this // post instance

    if (!thumbnail && mediaIndex === 0) {
      return null
    }

    if (mediaIndex !== 0) {
      thumbnail = this.media[mediaIndex]
    }

    if (!thumbnail.url) {
      if (!thumbnail.fallbackUrl) {
        return null
      }
      return thumbnail.fallbackUrl
    }
    return thumbnail.url
  }

  get lines() {
    return this.text.split(/\n/)
  }

  setIndex(index) {
    this.index = index
  }

  get ariaLabel() {
    switch (this.sourceName) {
      case PLATFORMS.INSTAGRAM: {
        return 'Content from Instagram'
      }
      case PLATFORMS.FACEBOOK: {
        return 'Content from Facebook'
      }
      case PLATFORMS.TWITTER: {
        return 'Content from Twitter'
      }
      case PLATFORMS.RSS: {
        return 'Content from RSS feed'
      }
      case PLATFORMS.TUMBLR: {
        return 'Content from Tumblr'
      }
      case PLATFORMS.YOUTUBE: {
        return 'Content from Youtube'
      }
      case PLATFORMS.PINTEREST: {
        return 'Content from Pintrest'
      }
      default: {
        return 'Custom content'
      }
    }
  }
}

export default Post
