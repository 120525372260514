import initializeFlow from './initializeFlow'
import flowsStore from './flowsStore'

const configuration = {
  bindEventListeners: false,
  forceRender: true,
  registerFlow: false,
}

export default function updateFlow(settings = {}) {
  if (!settings.key)
    throw new TypeError(
      `[Flowbox]: No key provided. Provide one with "window.flowbox('update', { key: 'flow-key' })"`,
    )

  const flow = flowsStore.get(settings.key)

  // Register the new settings in the store
  flowsStore.set({
    ...flow,
    ...settings,
  })

  return initializeFlow({ ...flow, ...settings }, configuration)
}
