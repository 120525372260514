import React from 'react'
import ReactDOM from 'react-dom'

import { VisiblePostsContextProvider } from './common/contexts/visiblePostsContext'
import FeedLoader from './common/feed-loader/FeedLoader'
import addEmbedEventListeners from './common/utilities/addEmbedEventListeners'
import isElementNearViewport from './common/utilities/isElementNearViewport'
import getFlowConfiguration from './common/utilities/getFlowConfiguration'
import flowsStore from './flowsStore'
import Locale from './models/Locale'
import detectWebp from './common/utilities/supportsWebp'
import setLocaleOnContainer from './common/utilities/setLocaleOnContainer'

const defaultConfiguration = {
  bindEventListeners: true,
  forceRender: false,
  registerFlow: true,
}

const getApiCompatibleTagsOperator = ({ tags, tagsOperator }) => {
  if (!tags || (Array.isArray(tags) && tags.length === 0)) return undefined
  if (!tagsOperator || !['any', 'all'].includes(tagsOperator)) return 'OR'

  return {
    any: 'OR',
    all: 'AND',
  }[tagsOperator]
}

export default function initializeFlow(settings = {}, configuration = defaultConfiguration) {
  const {
    allowCookies,
    container,
    context,
    key,
    lazyLoad = true,
    productId,
    tags,
    tagsOperator,
    setupAndStyling,
  } = settings
  if (!key)
    throw new TypeError(
      `[Flowbox]: No key provided. Provide one with "window.flowbox('init', { key: 'flow-key' })"`,
    )

  if (configuration.registerFlow) {
    flowsStore.set(settings)
  }

  const renderFlow = ({ removeEventListeners } = {}) => {
    const element = typeof container === 'string' ? document.querySelector(container) : container
    const shouldRender = lazyLoad ? isElementNearViewport(element) : true
    const locale = new Locale(settings.locale)

    if (!shouldRender && !configuration.forceRender) return false

    detectWebp(element).then(hasWebP => {
      if (hasWebP) {
        element.classList.add('flbx-webp')
      }
    })

    setLocaleOnContainer(element, locale)

    ReactDOM.render(
      <VisiblePostsContextProvider>
        <FeedLoader
          allowCookies={allowCookies}
          containerElement={element}
          context={context}
          feedKey={key}
          flowConfiguration={getFlowConfiguration(settings)}
          locale={locale}
          operator={getApiCompatibleTagsOperator({ tags, tagsOperator })}
          productId={productId}
          setupAndStyling={setupAndStyling}
          tags={Array.isArray(tags) ? tags.join(',') : tags}
        />
      </VisiblePostsContextProvider>,
      element,
    )

    if (removeEventListeners) {
      removeEventListeners()
    }

    return true
  }

  const didRender = renderFlow()

  if (!didRender && configuration.bindEventListeners) {
    addEmbedEventListeners(renderFlow)
  }

  return didRender
}
