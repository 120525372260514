import { validateColor, validateValue } from '../common/utilities/validateValues'

class General {
  constructor(data) {
    this.general = data.general || {}
    this.theme = data.theme
  }

  get defaultContainerWidth() {
    if (this.theme === 'single' || this.theme === 'single-auto') {
      return 1150
    }
    return undefined
  }

  get containerWidth() {
    const { containerWidth } = this.general
    const value = containerWidth && containerWidth.value
    return validateValue(value, this.defaultContainerWidth)
  }

  get hasContainerWidth() {
    const { containerWidth } = this.general
    const value = containerWidth && containerWidth.value
    return value !== null && value !== undefined
  }

  get containerFixedHeight() {
    const { fixedHeight } = this.general

    if (fixedHeight && fixedHeight.value) {
      const height = fixedHeight && fixedHeight.children && fixedHeight.children.height
      const value = height && height.value
      return validateValue(value, 400)
    }
    return null
  }

  get postsToShow() {
    const { displayLimit } = this.general
    if (displayLimit && displayLimit.value) {
      const postsToShow = displayLimit && displayLimit.children && displayLimit.children.postsToShow
      const value = postsToShow && postsToShow.value
      return validateValue(value, 8)
    }
    return null
  }

  get feedBackgroundColor() {
    const { backgroundColor } = this.general
    const value = backgroundColor && backgroundColor.value
    return validateColor(value, '#ffffff')
  }

  get isInfiniteScrollEnabled() {
    const { value } = this.general.infiniteScroll
    return validateValue(value, false)
  }

  get isMinimalistic() {
    const { value } = this.general.minimalistic
    return validateValue(value, false)
  }

  get defaultGutterSize() {
    if (this.theme === 'tile-grid' || this.theme === 'tile-grid-auto') {
      return 0
    }
    return 24
  }

  get gutterSize() {
    const { gutterSize } = this.general
    const value = gutterSize && gutterSize.value
    return validateValue(value, this.defaultGutterSize)
  }

  get postCount() {
    const { postCount } = this.general
    const value = postCount && postCount.value
    return validateValue(value, 100)
  }

  get hasStaticRows() {
    const { staticRows } = this.general
    const value = staticRows && staticRows.value
    return validateValue(value, false)
  }

  get rowCount() {
    const { staticRows } = this.general
    if (this.hasStaticRows) {
      const rowCount = staticRows && staticRows.children && staticRows.children.rowCount
      const value = rowCount && rowCount.value
      return validateValue(value, 5)
    }
    return null
  }

  get staticColumnsEnabled() {
    const { staticColumns } = this.general
    const value = staticColumns && staticColumns.value
    return validateValue(value, false)
  }

  get columnCount() {
    const columns = this.general.staticColumns
    if (this.staticColumnsEnabled) {
      const columnCount = columns && columns.children && columns.children.columnCount
      const value = columnCount && columnCount.value
      return validateValue(value, 4)
    }
    return null
  }

  get largeCards() {
    const { largeCards } = this.general
    const value = largeCards && largeCards.value
    return validateValue(value, false)
  }

  get sameSizePosts() {
    const { sameSizeCards } = this.general
    const value = sameSizeCards && sameSizeCards.value
    return validateValue(value, false)
  }

  get hasDisplayLimit() {
    const { displayLimit } = this.general
    const value = displayLimit && displayLimit.value
    return validateValue(value, false)
  }

  get likesAndComments() {
    const { likesAndComments } = this.general
    const value = likesAndComments && likesAndComments.value
    return validateValue(value, true)
  }

  get defaultHighlightTimeout() {
    if (this.theme === 'carousel' || this.theme === 'carousel-auto') {
      return 1
    }
    if (this.theme === 'single' || this.theme === 'single-auto') {
      return 5
    }
    return 8
  }

  get highlightTimeout() {
    const { highlightTimeout } = this.general
    const value = highlightTimeout && highlightTimeout.value
    return validateValue(value, this.defaultHighlightTimeout)
  }

  get rawFontFamily() {
    const { fontFamily } = this.general
    const value = fontFamily && fontFamily.value
    return validateValue(value, '_inherit')
  }

  get shouldLoadWebFont() {
    return this.rawFontFamily[0] !== '_'
  }

  get fontFamily() {
    if (this.shouldLoadWebFont) {
      // converts font names like: open-sans => Open Sans
      const fontFamily = this.rawFontFamily
        .split('-')
        .map(s => s[0].toUpperCase() + s.substr(1))
        .join('+')

      return `${fontFamily}:400,700:latin`
    }
    return this.rawFontFamily.substr(1)
  }

  get slidesToShowEnabled() {
    const { slidesToShow } = this.general
    const value = slidesToShow && slidesToShow.value
    return validateValue(value, false)
  }

  get slidesToShow() {
    if (!this.slidesToShowEnabled) {
      return null
    }

    const { slidesToShow } = this.general
    const toShow = slidesToShow && slidesToShow.children && slidesToShow.children.slidesToShow
    const value = toShow && toShow.value
    return validateValue(value, null)
  }

  get mobileSlidesToShowEnabled() {
    const { mobileSlidesToShow } = this.general
    const value = mobileSlidesToShow && mobileSlidesToShow.value
    return validateValue(value, false)
  }

  get mobileSlidesToShow() {
    if (!this.mobileSlidesToShowEnabled) {
      return 2
    }

    const { mobileSlidesToShow } = this.general
    const toShow =
      mobileSlidesToShow &&
      mobileSlidesToShow.children &&
      mobileSlidesToShow.children.mobileSlidesToShow
    const value = toShow && toShow.value
    return validateValue(value, 2)
  }
}

export default General
