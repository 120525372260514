export default function validateFeed(settings, errorMessage) {
  if (errorMessage) {
    console.warn(errorMessage) // eslint-disable-line no-console
    return false
  }

  if (Object.keys(settings).length === 0) {
    console.warn('Empty response from API. Are you using the correct key?') // eslint-disable-line no-console
    return false
  }

  return true
}
