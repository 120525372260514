import { POSTS_PER_PAGE } from '../utilities/constants'
import buildPublicFeedQuery from '../utilities/buildPublicFeedQuery'

export function decideIfFetchMore(posts, pinned, cursor) {
  return posts.length + pinned.length >= POSTS_PER_PAGE && (cursor !== null || cursor !== '')
}

export async function decideIfFetchMoreTags(posts, pinned, cursor, config) {
  if (cursor === null || cursor === '') {
    return false
  }

  if (posts.concat(pinned).length === 0 && (cursor !== null || cursor !== '')) {
    return false
  }

  try {
    const url = buildPublicFeedQuery(
      __API_ENDPOINT__,
      config.feedKey,
      cursor,
      false, // Fetches posts from the start so as to get new posts, if false uses cursor
      POSTS_PER_PAGE,
      config.productId,
      config.tags,
      config.operator,
      config.allowCookies,
    )
    const response = await fetch(url)
    const data = await response.json()
    return data.posts.length + data.pinned.length > 0
  } catch (e) {
    return false
  }
}
