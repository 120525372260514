import { validateValue } from '../common/utilities/validateValues'

class Ratings {
  constructor(elements) {
    this.ratings = {
      children: {},
      ...((elements && elements.ratings) || {}),
    }
  }

  get isEnabled() {
    return validateValue(this.ratings.value, false)
  }

  get threshold() {
    const { threshold } = this.ratings.children
    const value = threshold && threshold.value
    return validateValue(value, 0)
  }

  get shouldShowReviews() {
    const { show_number_of_reviews: showNumberOfReviews } = this.ratings.children
    const value = showNumberOfReviews && showNumberOfReviews.value
    return validateValue(value, false)
  }
}

export default Ratings
