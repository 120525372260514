import psl from 'psl'

const COOKIE_EXPERATION_DAYS = 730

function getDomain(hostname = window.location.hostname) {
  return psl.get(hostname)
}

function generateUuid() {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

function getCookieExpirationDate() {
  const date = new Date()
  date.setDate(date.getDate() + COOKIE_EXPERATION_DAYS)
  return date
}

function setCookie(cookieName, uuid, expires, domain, allowCookies) {
  if (allowCookies) {
    const cookieFragments = [
      `${cookieName}=${uuid}`,
      `expires=${expires.toGMTString()}`,
      'path=/',
      domain ? `domain=${domain}` : '',
      'SameSite=Lax',
    ]
    const cookie = cookieFragments.filter(Boolean).join('; ')

    document.cookie = cookie
    return uuid
  }
  return null
}

function getCookie(cookieName, allowCookies) {
  const name = `${cookieName}=`
  const cookieDecoded = decodeURIComponent(document.cookie)
  const cookieArray = cookieDecoded.split('; ')
  let cookieId
  cookieArray.forEach(val => {
    if (val.indexOf(name) === 0) cookieId = val.substring(name.length)
  })
  if (!cookieId) {
    const uuid = generateUuid()
    const domain = getDomain(window.location.hostname)
    const expires = getCookieExpirationDate()
    const id = setCookie(cookieName, uuid, expires, domain, allowCookies)
    return id
  }
  return cookieId
}

export { getCookie, getDomain, setCookie }
