import { getCookie } from './cookie'

export default function buildPublicFeedQuery(
  baseUrl,
  feedKey,
  cursor,
  refresh,
  limit,
  productId,
  tags,
  operator,
  allowCookies,
  locale,
) {
  let queryString = '?cursor='
  if (cursor && !refresh) {
    queryString += encodeURIComponent(JSON.stringify(cursor))
  } else {
    queryString += 'null'
  }

  if (limit) {
    // Potentially we can make each feed load one row of posts at a time by setting limit to an amount base on some setting

    queryString += `&postsPerPage=${limit}`
  }

  if (productId) {
    queryString += `&productId=${encodeURIComponent(productId)}`
  } else if (tags) {
    queryString += `&tags=${encodeURIComponent(tags)}`
    if (operator) {
      queryString += `&operator=${operator}`
    }
  }

  if (locale) {
    queryString += `&locale=${locale}`
  }

  const id = getCookie('_flowbox', allowCookies)
  queryString += `&device=${id}`

  return `${baseUrl}/feed/${feedKey}${queryString}`
}
