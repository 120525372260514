import { validateColor, validateValue } from '../common/utilities/validateValues'

class LoadMoreButton {
  constructor(elements) {
    this.loadMoreButton = {
      children: {},
      ...((elements && elements.loadMoreButton) || {}),
    }
  }

  get shouldShowLoadMoreButton() {
    return validateValue(this.loadMoreButton.value, true)
  }

  get backgroundColor() {
    const { backgroundColor } = this.loadMoreButton.children
    const value = backgroundColor && backgroundColor.value
    return validateColor(value, null)
  }

  get textColor() {
    const { color } = this.loadMoreButton.children
    const value = color && color.value
    return validateColor(value, null)
  }

  get rounding() {
    const { rounded } = this.loadMoreButton.children
    const value = rounded && rounded.value
    return validateValue(value, 4)
  }
}

export default LoadMoreButton
