import sendTrackingEvent from '../utilities/sendTrackingEvent'

function sendEmbedLoadedEvent(component) {
  const instance = component
  const { feedKey, allowCookies } = instance.props
  const visiblePostIds = instance.context?.visiblePostIds || []
  instance.isEmbedLoaded = true
  if (visiblePostIds?.length) {
    sendTrackingEvent({
      action: 'view',
      allowCookies,
      eventName: 'embed_viewed',
      flowKey: feedKey,
      target: 'flow',
      visiblePostIds,
    })
  }
}

export default sendEmbedLoadedEvent
