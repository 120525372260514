import { validateText, validateValue } from '../common/utilities/validateValues'

export default class Product {
  constructor(elements) {
    this.products = {
      children: {},
      ...((elements && elements.products) || {}),
    }
  }

  get availabilityBehavior() {
    const behavior = this.products.children.productAvailabilityBehavior
    const value = behavior && behavior.value
    return validateValue(value, 'none')
  }

  get buttonText() {
    const buttonText = this.products.children.buttontext
    const value = buttonText && buttonText.value
    return validateText(value, 'Buy now')
  }

  get listTitleText() {
    const { title } = this.products.children
    const value = title && title.value
    return validateValue(value, 'Shop now')
  }

  get showPrice() {
    const showPrice = this.products.children.show_price
    const value = showPrice && showPrice.value
    return validateValue(value, true)
  }

  // eslint-disable-next-line class-methods-use-this
  get showProducts() {
    /*
       const products = this.data && this.data.elements && this.data.elements.products
       const value = products && products.value
       Always return true for now, since we are not ready to enable this for our customers.
       We need to check and see how many customers have Enable products off in S&S and still displays products.
       return validateValue(value, true)
     */
    return true
  }

  get salesBadgeText() {
    const badgeText = this.products.children.badge_text
    const value = badgeText && badgeText.value
    return validateValue(value, 'text')
  }

  get showSalesBadge() {
    const salesBadge = this.products.children.sales_badge
    const value = salesBadge && salesBadge.value
    return validateValue(value, true)
  }

  get showSalesPrice() {
    const salesPrice = this.products.children.sales_price
    const value = salesPrice && salesPrice.value
    return validateValue(value, true)
  }
}
