function isDebugEnabled() {
  try {
    return (window.localStorage.getItem('debug') || '').startsWith('flowbox:')
  } catch (e) {
    return false;
  }
}

function debug(namespace) {
  return function logger(...args) {
    if (isDebugEnabled()) {
      console.debug(`flowbox:${namespace}`, ...args) // eslint-disable-line no-console
    }
  }
}

export default debug
