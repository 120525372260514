export const YOUTUBE_VIDEO_ID_REGEX = /youtube.com\/watch\?v=(.+)/
export const YOUTUBE_VIDEO_ID_EMBED_REGEX = /youtube.com\/embed\/(.[^??]+)/
export const PLATFORMS = {
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TUMBLR: 'tumblr',
  YOUTUBE: 'youtube',
  RSS: 'rss',
  LINKEDIN: 'linkedin',
  CUSTOM: 'custom',
}

/*
We settled on 29 as a post per page since it's a prime number and therefore only divisible by 1 or itself.
The public feed lambda limits us to get 30 posts and 29 is the closest to that.
We wanted to use a prime number since that would never be divisible by the column count.
*/
export const POSTS_PER_PAGE = 29

export const supportedLanguages = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'ms',
  'nb',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh',
]
