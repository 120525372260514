// Converts all valid hex colors (3 hex digits, 6 hex digits and 8 hex digits )
// to an 8 hex digit color
export default function normalizeColor(color) {
  if (color === null || color === undefined) {
    return color
  }

  const value = color.replace('#', '').toLowerCase()
  const numDigits = value.length

  if (numDigits === 3) {
    const red = value[0]
    const green = value[1]
    const blue = value[2]

    return `#${red}${red}${green}${green}${blue}${blue}ff`
  }

  if (numDigits === 6) {
    return `#${value}ff`
  }

  return `#${value}`
}
