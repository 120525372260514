import buildPublicFeedQuery from '../utilities/buildPublicFeedQuery'
import validateFeed from './validate-feed'
import { decideIfFetchMore } from './decide-if-fetch-more'
import { POSTS_PER_PAGE } from '../utilities/constants'
import Post from '../../models/Post'

/**
 *
 * @param {Object.<string, *>} config
 * @param {boolean=} [shouldFetchFromStart=false]
 * @returns {Promise.<Array.<*>>}
 */
async function fetchFeed(config, shouldFetchFromStart = false) {
  const url = buildPublicFeedQuery(
    __API_ENDPOINT__,
    config.feedKey,
    config.cursor,
    shouldFetchFromStart, // Fetches posts from the start so as to get new posts, if false uses cursor
    POSTS_PER_PAGE,
    config.productId,
    config.tags,
    config.operator,
    config.allowCookies,
    config?.locale?.locale,
  )

  const response = await fetch(url)

  const { settings, posts, pinned, cursor, msg = '' } = await response.json()

  if (validateFeed(settings, msg)) {
    const shouldFetchMore = decideIfFetchMore(posts, pinned, cursor)
    const mPosts = [...pinned.map(p => new Post(p)), ...posts.map(p => new Post(p))]

    return [settings, mPosts, cursor, shouldFetchMore]
  }

  return undefined
}

export default fetchFeed
