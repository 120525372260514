import Elements from './Elements'
import Banner from './Banner'
import Products from './Products'
import Ratings from './Ratings'
import LoadMoreButton from './LoadMoreButton'
import General from './General'
import CustomCss from './CustomCss'

export default class Settings {
  constructor(data) {
    this.data = data
    this.elements = new Elements(data)
    this.banner = new Banner(data.elements)
    this.locale = data.locale
    this.products = new Products(data.elements)
    this.ratings = new Ratings(data.elements)
    this.loadMoreButton = new LoadMoreButton(data.elements)
    this.general = new General(data)
    this.allowCookies = data.allowCookies
    this.customCss = new CustomCss(data.customCss)
  }

  get autoplayVideos() {
    return this.elements.autoplayVideos
  }

  get gateCarouselPostIndicator() {
    return this.elements.gateCarouselPostIndicator
  }

  get carouselPostArrowBackground() {
    return this.elements.carouselPostArrowBackground
  }

  get containerWidth() {
    return this.general.containerWidth
  }

  get hasContainerWidth() {
    return this.general.hasContainerWidth
  }

  get containerFixedHeight() {
    return this.general.containerFixedHeight
  }

  get bannerPosition() {
    return this.banner.position
  }

  get showBottomBanner() {
    return this.banner.showBottom
  }

  get showCardBanner() {
    return this.banner.showCard
  }

  get showTopBanner() {
    return this.banner.showTop
  }

  get bannerBackgroundColor() {
    return this.banner.backgroundColor
  }

  get bannerPadding() {
    return this.banner.padding
  }

  get bannerLogoUrl() {
    return this.banner.logoUrl
  }

  get bannerHideLogoMobile() {
    return this.banner.hideLogoMobile
  }

  get bannerPrimaryText() {
    return this.banner.primaryText
  }

  get bannerPrimaryFontSize() {
    return this.banner.primaryFontSize
  }

  get bannerPrimaryColor() {
    return this.banner.primaryColor
  }

  get bannerSecondaryText() {
    return this.banner.secondaryText
  }

  get bannerSecondaryFontSize() {
    return this.banner.secondaryFontSize
  }

  get bannerSecondaryColor() {
    return this.banner.secondaryColor
  }

  get showBanner() {
    return this.banner.shouldShow
  }

  get displayName() {
    return this.elements.displayName
  }

  get postsToShow() {
    return this.general.postsToShow
  }

  get feedBackgroundColor() {
    return this.general.feedBackgroundColor
  }

  get hashtagColor() {
    return this.elements.hashtagColor
  }

  get isInfiniteScrollEnabled() {
    return this.general.isInfiniteScrollEnabled
  }

  get isMinimalistic() {
    return this.general.isMinimalistic
  }

  get isRatingEnabled() {
    return this.ratings.isEnabled
  }

  get ratingThreshold() {
    return this.ratings.threshold
  }

  get largeCards() {
    return this.general.largeCards
  }

  get sameSizePosts() {
    return this.general.sameSizePosts
  }

  get showNumberOfReviews() {
    return this.ratings.shouldShowReviews
  }

  get showPlatformIcon() {
    return this.elements.showPlatformIcon
  }

  get showProfileImage() {
    return this.elements.showProfileImage
  }

  get postAge() {
    return this.elements.postAge
  }

  get postBackgroundColor() {
    return this.elements.backgroundColor
  }

  get postColor() {
    return this.elements.color
  }

  get postCount() {
    return this.general.postCount
  }

  get postHeaderCentered() {
    return this.elements.postHeaderCentered
  }

  get postInteractivity() {
    return this.elements.postInteractivity
  }

  get rowCount() {
    return this.general.rowCount
  }

  get gutterSize() {
    return this.general.gutterSize
  }

  get showLoadMoreButton() {
    return this.loadMoreButton.shouldShowLoadMoreButton
  }

  get loadMoreButtonBackgroundColor() {
    return this.loadMoreButton.backgroundColor
  }

  get loadMoreButtonTextColor() {
    return this.loadMoreButton.textColor
  }

  get loadMoreButtonRounding() {
    return this.loadMoreButton.rounding
  }

  get productButtonText() {
    return this.products.buttonText
  }

  get productListTitleText() {
    return this.products.listTitleText
  }

  get hasDisplayLimit() {
    return this.general.hasDisplayLimit
  }

  get hasStaticRows() {
    return this.general.hasStaticRows
  }

  get showLikesAndComments() {
    return this.general.likesAndComments
  }

  get showPrice() {
    return this.products.showPrice
  }

  get showPostText() {
    return this.elements.shouldShowPostText
  }

  get showPostIndicator() {
    return this.elements.shouldShowPostIndicator
  }

  get showPostGateText() {
    return this.elements.shouldShowPostTextGate
  }

  get productAvailabilityBehavior() {
    return this.products.availabilityBehavior
  }

  get hideOutOfStockProducts() {
    return this.productAvailabilityBehavior === 'hide-product'
  }

  get salesBadgeText() {
    return this.products.salesBadgeText
  }

  get showProductStockStatus() {
    return this.productAvailabilityBehavior === 'display'
  }

  get showSalesBadge() {
    return this.products.showSalesBadge
  }

  get showSalesPrice() {
    return this.products.showSalesPrice
  }

  get showProducts() {
    return this.products.showProducts
  }

  get socialCommerceButtonBackgroundColor() {
    return this.elements.socialCommerceButtonBackgroundColor
  }

  get socialCommerceButtonColor() {
    return this.elements.socialCommerceButtonColor
  }

  get socialCommerceHollowButton() {
    return this.elements.socialCommerceHollowButton
  }

  get socialCommerceButtonBorderRadius() {
    return this.elements.socialCommerceButtonBorderRadius
  }

  get socialCommerceButtonLarge() {
    return this.elements.socialCommerceButtonLarge
  }

  get socialCommerceButtonPosition() {
    return this.elements.socialCommerceButtonPosition
  }

  get highlightTimeout() {
    return this.general.highlightTimeout
  }

  get rawFontFamily() {
    return this.general.rawFontFamily
  }

  get shouldLoadWebFont() {
    return this.general.shouldLoadWebFont
  }

  get fontFamily() {
    return this.general.fontFamily
  }

  get largeFontSize() {
    return this.elements.largeFontSize
  }

  get feedFontSize() {
    return this.elements.feedFontSize
  }

  get smallFontSize() {
    return this.elements.smallFontSize
  }

  get slidesToShowEnabled() {
    return this.general.slidesToShowEnabled
  }

  get slidesToShow() {
    return this.general.slidesToShow
  }

  get mobileSlidesToShowEnabled() {
    return this.general.mobileSlidesToShowEnabled
  }

  get mobileSlidesToShow() {
    return this.general.mobileSlidesToShow
  }

  get staticColumnsEnabled() {
    return this.general.staticColumnsEnabled
  }

  get columnCount() {
    return this.general.columnCount
  }

  get isAutoTheme() {
    const t = this.theme
    if (!t) {
      return false
    }

    return (
      t === 'card-flow-auto' ||
      t === 'card-flow-auto-centered' ||
      t === 'carousel-auto' ||
      t === 'single-auto' ||
      t === 'tile-grid-auto'
    )
  }

  get isCenteredTheme() {
    const t = this.theme
    if (!t) {
      return false
    }

    return t === 'card-flow-centered' || t === 'card-flow-auto-centered'
  }

  get theme() {
    return this.data && this.data.theme
  }
}
