import React, { Component } from 'react'
import { string, shape, arrayOf, oneOfType, func, instanceOf } from 'prop-types'

import Settings from '../../../models/settings'
import CustomCss from './theme-components/custom-css'
import GateContext from '../../utilities/gateContext'

import '../../../styles/default.scss'

function importTheme(theme) {
  switch (theme) {
    case 'gate':
      return import(/* webpackChunkName: "gate" */ '../gate/gate')
    case 'card-flow':
    case 'card-flow-auto':
    case 'card-flow-centered':
    case 'card-flow-auto-centered':
      return import(/* webpackChunkName: "cards" */ './themes/card-flow/card-flow')
    case 'carousel':
    case 'carousel-auto':
      return import(/* webpackChunkName: "carousel" */ './themes/carousel/carousel')
    case 'single':
    case 'single-auto':
      return import(/* webpackChunkName: "single" */ './themes/single/single')
    case 'tile-grid':
    case 'tile-grid-auto':
    default:
      return import(/* webpackChunkName: "grid" */ './themes/tile-grid/tile-grid')
  }
}

export default class Theme extends Component {
  static propTypes = {
    context: string,
    feedProps: shape({
      containerElement: shape().isRequired,
      context: string,
      feed: shape({
        cursor: oneOfType([shape(), string]),
        pinned: arrayOf(shape()),
        posts: arrayOf(shape()),
      }),
      feedKey: string,
      load: func.isRequired,
      locale: shape(),
      settings: instanceOf(Settings).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    context: undefined,
  }

  constructor() {
    super()
    this.state = {
      theme: null,
      gate: () => null,
      chosenTheme: () => null,
      gateIsOpen: false,
      post: null,
    }

    this.onGateClose = this.onGateClose.bind(this)
  }

  componentDidMount() {
    const { theme } = this.props.feedProps.settings
    this.loadTheme(theme)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.gateIsOpen && this.state.gateIsOpen) {
      this.loadGate()
    }
  }

  onGateClose() {
    this.setState({
      gateIsOpen: false,
      post: null,
    })
  }

  onGateOpen(post) {
    this.setState({
      gateIsOpen: true,
      post,
    })
  }

  getAriaLabel() {
    const { locale } = this.props.feedProps.settings
    switch (this.state.theme) {
      case 'card-flow':
      case 'card-flow-auto':
      case 'card-flow-centered':
      case 'card-flow-auto-centered':
      case 'tile-grid':
      case 'tile-grid-auto':
        return locale.getTranslation('cardAndTileAriaLabel')
      case 'carousel':
      case 'carousel-auto':
        return locale.getTranslation('carouselAriaLabel')
      case 'single':
      case 'single-auto':
        return 'Below is a post from a social media account'
      default:
        return null
    }
  }

  loadTheme(theme) {
    if (!theme) {
      return
    }

    importTheme(theme).then(module => {
      const chosenTheme = module.default

      if (chosenTheme !== undefined) {
        this.setState({
          chosenTheme,
          theme,
        })
      }
    })
  }

  loadGate() {
    importTheme('gate').then(module => {
      const gate = module.default

      if (gate !== undefined) {
        this.setState({
          gate,
        })
      }
    })
  }

  render() {
    const ChosenTheme = this.state.chosenTheme
    const Gate = this.state.gate

    return (
      <div
        aria-label={this.getAriaLabel()}
        className={`bzfy-o-context bzfy-o-context-${this.props.context}`}
        role="article"
      >
        <CustomCss settings={this.props.feedProps.settings} />
        <GateContext.Provider
          value={{
            gateIsOpen: this.state.gateIsOpen,
            openGate: post => {
              this.setState({
                gateIsOpen: true,
                post,
              })
            },
            onGateClose: this.onGateClose,
          }}
        >
          <ChosenTheme {...this.props.feedProps} />
        </GateContext.Provider>
        {this.state.gateIsOpen ? (
          <Gate
            feedKey={this.props.feedProps.feedKey}
            onClose={this.onGateClose}
            post={this.state.post}
            posts={this.props.feedProps.feed.posts}
            settings={this.props.feedProps.settings}
          />
        ) : null}
      </div>
    )
  }
}
