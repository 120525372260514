function convertToCorrectLocale(locale) {
  const deprecatedLocales = {
    en: 'en-GB',
    sv: 'sv-SE',
    nl: 'nl-NL',
    fi: 'fi-FI',
    da: 'da-DK',
    dk: 'da-DK',
    no: 'no-NO',
    nb: 'nb-NO',
    de: 'de-DE',
    fr: 'fr-FR',
    es: 'es-ES',
    pt: 'pt-PT',
  }
  return deprecatedLocales[locale]
}

export default convertToCorrectLocale
