import { instanceOf } from 'prop-types'
import React from 'react'

import Settings from '../../../../models/settings'

export default function CustomCss({ settings }) {
  return settings.customCss.customCss ? <style>{settings.customCss.toString()}</style> : null
}

CustomCss.propTypes = {
  settings: instanceOf(Settings).isRequired,
}
