import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const VisiblePostsContext = React.createContext()

function VisiblePostsContextProvider({ children }) {
  const [visiblePostIds, setVisiblePostIds] = useState([])

  const addVisiblePostId = useCallback(
    postId => setVisiblePostIds(prevState => [...prevState, postId]),
    [],
  )

  const removeVisiblePostId = useCallback(
    postId => setVisiblePostIds(prevState => prevState.filter(id => id !== postId)),
    [],
  )

  return (
    <VisiblePostsContext.Provider value={{ visiblePostIds, addVisiblePostId, removeVisiblePostId }}>
      {children}
    </VisiblePostsContext.Provider>
  )
}

VisiblePostsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

VisiblePostsContextProvider.defaultProps = {
  children: undefined,
}

export { VisiblePostsContextProvider, VisiblePostsContext }
