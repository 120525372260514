// Stores flow configurations so that the user can easily
// update and re-render a flow by passing at least the key
const flowConfigurations = {}

const registerFlowConfiguration = settings => {
  const currentConfig = flowConfigurations[settings.key]

  flowConfigurations[settings.key] = currentConfig ? { ...currentConfig, ...settings } : settings

  return flowConfigurations[settings.key]
}

function setupAndStylingStoreHasKey(feedKey) {
  return Boolean(window.setupAndStylingStore && window.setupAndStylingStore[feedKey])
}

function setupAndStylingStore(feedKey) {
  if (setupAndStylingStoreHasKey(feedKey)) {
    const setupAndStylingSettings = {
      ...window.setupAndStylingStore[feedKey],
    }
    window.setupAndStylingStore[feedKey] = undefined
    return setupAndStylingSettings
  }
  return undefined
}

export default {
  get: key => flowConfigurations[key],
  set: registerFlowConfiguration,
}

export { setupAndStylingStore, setupAndStylingStoreHasKey }
