export default function loadLanguage(language) {
  /* eslint complexity: ["error", {"max": 40}] */
  switch (language) {
    case 'ar':
      return import(/* webpackChunkName: "embed-ar" */ '../lang/ar.js')
    case 'bg':
      return import(/* webpackChunkName: "embed-bg" */ '../lang/bg.js')
    case 'cs':
      return import(/* webpackChunkName: "embed-cs" */ '../lang/cs.js')
    case 'da':
      return import(/* webpackChunkName: "embed-da" */ '../lang/da.js')
    case 'de':
      return import(/* webpackChunkName: "embed-de" */ '../lang/de.js')
    case 'en':
      return import(/* webpackChunkName: "embed-en" */ '../lang/en.js')
    case 'el':
      return import(/* webpackChunkName: "embed-el" */ '../lang/el.js')
    case 'es':
      return import(/* webpackChunkName: "embed-es" */ '../lang/es.js')
    case 'et':
      return import(/* webpackChunkName: "embed-et" */ '../lang/et.js')
    case 'fi':
      return import(/* webpackChunkName: "embed-fi" */ '../lang/fi.js')
    case 'fr':
      return import(/* webpackChunkName: "embed-fr" */ '../lang/fr.js')
    case 'hr':
      return import(/* webpackChunkName: "embed-hr" */ '../lang/hr.js')
    case 'hu':
      return import(/* webpackChunkName: "embed-hu" */ '../lang/hu.js')
    case 'id':
      return import(/* webpackChunkName: "embed-id" */ '../lang/id.js')
    case 'is':
      return import(/* webpackChunkName: "embed-is" */ '../lang/is.js')
    case 'it':
      return import(/* webpackChunkName: "embed-it" */ '../lang/it.js')
    case 'ja':
      return import(/* webpackChunkName: "embed-ja" */ '../lang/ja.js')
    case 'ko':
      return import(/* webpackChunkName: "embed-ko" */ '../lang/ko.js')
    case 'lt':
      return import(/* webpackChunkName: "embed-lt" */ '../lang/lt.js')
    case 'lv':
      return import(/* webpackChunkName: "embed-lv" */ '../lang/lv.js')
    case 'ms':
      return import(/* webpackChunkName: "embed-ms" */ '../lang/ms.js')
    case 'no':
    case 'nb':
      return import(/* webpackChunkName: "embed-nb" */ '../lang/nb.js')
    case 'nl':
      return import(/* webpackChunkName: "embed-nl" */ '../lang/nl.js')
    case 'pl':
      return import(/* webpackChunkName: "embed-pl" */ '../lang/pl.js')
    case 'pt':
      return import(/* webpackChunkName: "embed-pt" */ '../lang/pt.js')
    case 'ro':
      return import(/* webpackChunkName: "embed-ro" */ '../lang/ro.js')
    case 'ru':
      return import(/* webpackChunkName: "embed-ru" */ '../lang/ru.js')
    case 'sk':
      return import(/* webpackChunkName: "embed-sk" */ '../lang/sk.js')
    case 'sl':
      return import(/* webpackChunkName: "embed-sl" */ '../lang/sl.js')
    case 'sv':
      return import(/* webpackChunkName: "embed-sv" */ '../lang/sv.js')
    case 'th':
      return import(/* webpackChunkName: "embed-th" */ '../lang/th.js')
    case 'tr':
      return import(/* webpackChunkName: "embed-tr" */ '../lang/tr.js')
    case 'uk':
      return import(/* webpackChunkName: "embed-uk" */ '../lang/uk.js')
    case 'vi':
      return import(/* webpackChunkName: "embed-vi" */ '../lang/vi.js')
    case 'zh':
      return import(/* webpackChunkName: "embed-zh" */ '../lang/zh.js')
    default:
      return import(/* webpackChunkName: "embed-en" */ '../lang/en.js')
  }
}
