function getFlowConfiguration(settings, version = '2.0') {
  return {
    context: settings.context,
    lazyLoad: settings.lazyLoad,
    locale: settings.locale,
    isDom: typeof container === 'string',
    version,
  }
}

export default getFlowConfiguration
