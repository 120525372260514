import { validateColor, validateValue } from '../common/utilities/validateValues'

class Elements {
  constructor(data) {
    this.elements = data.elements || {}
    this.theme = data.theme
  }

  get autoplayVideos() {
    const { autoplayVideos } = this.elements
    const value = autoplayVideos && autoplayVideos.value
    return validateValue(value, false)
  }

  get defaultBackgroundColor() {
    if (this.theme === 'tile-grid' || this.theme === 'tile-grid-auto') {
      return '#000000'
    }
    return '#f2f2f2'
  }

  get backgroundColor() {
    const { backgroundColor } = this.elements
    const value = backgroundColor && backgroundColor.value
    return validateColor(value, this.defaultBackgroundColor)
  }

  get defaultColor() {
    if (this.theme === 'tile-grid' || this.theme === 'tile-grid-auto') {
      return '#ffffff'
    }
    return '#000000'
  }

  get hasArrowBackground() {
    const { carouselPostArrowBackground } = this.elements
    const value = carouselPostArrowBackground && carouselPostArrowBackground.value
    return validateValue(value, false)
  }

  get color() {
    const { color } = this.elements
    const value = color && color.value
    return validateColor(value, this.defaultColor)
  }

  get displayName() {
    const details = this.elements.contributorDetails
    const displayName = details && details.children && details.children.displayName
    const value = displayName && displayName.value
    return validateValue(value, true)
  }

  get hashtagColor() {
    const { hashtagColor } = this.elements
    const value = hashtagColor && hashtagColor.value
    return validateColor(value, undefined)
  }

  get postAge() {
    const details = this.elements.contributorDetails
    const postAge = details && details.children && details.children.postAge
    const value = postAge && postAge.value
    return validateValue(value, true)
  }

  get postHeaderCentered() {
    const { headerCentered } = this.elements
    const value = headerCentered && headerCentered.value
    return validateValue(value, false)
  }

  get postInteractivity() {
    const { postInteractivity } = this.elements
    const value = postInteractivity && postInteractivity.value
    return validateValue(value, true)
  }

  get showPlatformIcon() {
    const details = this.elements.contributorDetails
    const platformIcon = details && details.children && details.children.platformIcon
    const value = platformIcon && platformIcon.value
    return validateValue(value, true)
  }

  get showProfileImage() {
    const details = this.elements.contributorDetails
    const profileImage = details && details.children && details.children.profileImage
    const value = profileImage && profileImage.value
    return validateValue(value, true)
  }

  get shouldShowPostText() {
    const { postText } = this.elements
    const value = postText && postText.value
    return validateValue(value, true)
  }

  get shouldShowPostIndicator() {
    const { postIndicator } = this.elements
    const value = postIndicator && postIndicator.value
    return validateValue(value, true)
  }

  get largeFontSize() {
    const { fontSize } = this.elements
    const value = fontSize && fontSize.value
    return (24 * validateValue(value, 10)) / 10
  }

  get feedFontSize() {
    const { fontSize } = this.elements
    const value = fontSize && fontSize.value
    return (16 * validateValue(value, 10)) / 10
  }

  get smallFontSize() {
    const { fontSize } = this.elements
    const value = fontSize && fontSize.value
    return (14 * validateValue(value, 10)) / 10
  }

  get shouldShowPostTextGate() {
    const { postTextGate } = this.elements
    const value = postTextGate && postTextGate.value
    return validateValue(value, true)
  }

  get socialCommerceButtonBackgroundColor() {
    const backgroundColor = this.elements.socialCommerceButtonBackgroundColor
    const value = backgroundColor && backgroundColor.value
    return validateColor(value, '#ffffff')
  }

  get socialCommerceButtonColor() {
    const color = this.elements.socialCommerceButtonColor
    const value = color && color.value
    return validateColor(value, '#000000')
  }

  // eslint-disable-next-line class-methods-use-this
  get socialCommerceHollowButton() {
    console.warn('DEPRECATED SETTING socialCommerceHollowButton') // eslint-disable-line no-console
    return false
  }

  get socialCommerceButtonBorderRadius() {
    const borderRadius = this.elements.socialCommerceButtonRounded
    const value = borderRadius && borderRadius.value
    return validateValue(value, 4)
  }

  get socialCommerceButtonLarge() {
    const large = this.elements.socialCommerceButtonLarge
    const value = large && large.value
    return validateValue(value, false)
  }

  get socialCommerceButtonPosition() {
    const position = this.elements.socialCommerceButtonPosition
    const value = position && position.value
    return validateValue(value, 'top')
  }

  get gateCarouselPostIndicator() {
    const { gateCarouselPostIndicator } = this.elements
    const value = gateCarouselPostIndicator && gateCarouselPostIndicator.value
    return validateValue(value, false)
  }

  get carouselPostArrowBackground() {
    const { carouselPostArrowBackground } = this.elements
    const value = carouselPostArrowBackground && carouselPostArrowBackground.value
    return validateValue(value, false)
  }
}

export default Elements
