import { getCookie } from './cookie'

export default function sendTrackingEvent({
  action,
  allowCookies,
  ctaType,
  eventName,
  flowKey,
  postId,
  postPosition,
  productKey,
  target,
  visiblePostIds,
  visibleProductKeys,
} = {}) {
  if (!allowCookies) {
    return Promise.reject(new Error('Rejected does not allow cookies'))
  }

  const id = getCookie('_flowbox', allowCookies)
  if (!id) return Promise.resolve()

  const event = {
    cookies_disabled: !allowCookies,
    created_at: new Date().toISOString(),
    data: {
      action,
      event_name: eventName,
      flow_key: flowKey,
      target,
    },
    from_url: window.location.href,
    user_id: id,
    version: '1.0.0',
  }

  if (ctaType) {
    event.data.cta_type = ctaType
  }

  if (postId) {
    event.data.post_id = postId
  }

  if (postPosition || postPosition === 0) {
    event.data.post_position = postPosition
  }

  if (productKey) {
    event.data.product_Key = productKey
  }

  if (visiblePostIds) {
    event.data.visible_post_ids = visiblePostIds
  }

  if (visibleProductKeys) {
    event.data.visible_product_keys = visibleProductKeys
  }

  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve()
  }

  return fetch(`${__FLOWBOX_ANALYTICS_ENDPOINT__}`, {
    body: JSON.stringify(event),
    method: 'POST',
    headers: {
      authorization: `FlowKey ${flowKey}`,
      'Content-Type': 'application/json',
    },
  })
}
